<template>
    <inner-page-card heading="Update Auction" :link-back="true">

        <div class="container" v-if="loading">
            <div class="card bg-info">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div class="container" v-else-if="error">
            <div class="card bg-danger fl-te-c">
                <div>
                    <error-icon/>
                    Failed to load data from the server.
                </div>
                <div>
                    <a class="pointer" @click="loadData()"><i class="fa fa-refresh"></i> reload</a>
                </div>
            </div>
        </div>

        <div v-else>

            <div class="p-3 mb-3 bg-danger c-badge" v-if="errorMessage">{{ errorMessage }}</div>

            <s-form class="row" ref="formValidator">
                <!--                <div v-if="currentUser.is_superuser || currentUser.is_ventura_admin" class="col-md-12 col-12 fl-eqh-r">-->
                <!--                    <btn type="button" v-if="!rules.is_third_party"-->
                <!--                         @click="changeAuctionType"-->
                <!--                         text="Other auction" class="btn btn-basic-b"/>-->
                <!--                    <btn type="button" v-else @click="changeAuctionType"-->
                <!--                         text="Ventura Auction" class="btn btn-basic-b"/>-->
                <!--                </div>-->
                <template v-if="rules.is_third_party">

                    <div class="col-12">
                        <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Other Details</h3>
                    </div>

                    <div class="col-md-6 col-12">
                        <validated-input :label="rules.is_third_party ? 'Name*' : 'Name'" placeholder="Name"
                                         class="c-form-input c-input-2"
                                         :rules="{required: rules.is_third_party}" name="Name"
                                         v-model="model.third_party_name" :disabled="saving"></validated-input>
                    </div>

                    <div class="col-md-6 col-12">
                        <validated-input :label="rules.is_third_party ? 'Contact no*' : 'Contact no'"
                                         onkeypress="return event.charCode &gt;= 48 &amp;&amp; event.charCode &lt;= 57"
                                         placeholder="Contact no"
                                         class="c-form-input c-input-2"
                                         :rules="{required: rules.is_third_party, min:10, max:10}" name="Contact no*"
                                         v-model="model.third_party_contact_no" :disabled="saving"></validated-input>
                    </div>

                    <div class="col-md-6 col-12">
                        <validated-input :label="rules.is_third_party ? 'E-Mail*' : 'E-Mail'" placeholder="E-Mail"
                                         class="c-form-input c-input-2" type="email"
                                         :rules="{required: rules.is_third_party}" name="E-Mail"
                                         v-model="model.third_party_email" :disabled="saving"></validated-input>
                    </div>

                    <div class="col-md-6 col-12">
                        <validated-input label="Link" placeholder="Link" class="c-form-input c-input-2"
                                         :rules="{required: false}" name="Link"
                                         v-model="model.third_party_link" :disabled="saving"></validated-input>
                    </div>
                </template>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Auction Details</h3>
                </div>

                <div class="col-md-12 col-12">
                    <validated-input label="Title*" placeholder="Title"
                                     class="c-form-input c-input-2"
                                     :rules="{required: true}" name="Title"
                                     v-model="model.title" :disabled="saving"></validated-input>
                </div>

                <div class="col-12">
                    <validated-text-area class="c-form-input"
                                         :label="'Description' + (!rules.is_third_party ? '*' : '')"
                                         :rules="{required: !rules.is_third_party}" name="Description"
                                         v-model="model.description" :disabled="saving"></validated-text-area>
                </div>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Event Details</h3>
                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'Event Type' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input c-input-2"
                                     :rules="{required: !rules.is_third_party}" name="Event type"
                                     v-model="model.event_type" :disabled="saving"></validated-input>
                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'Event No' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Event number"
                                     v-model="model.event_number" :disabled="saving"></validated-input>
                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'Ref. No.' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Ref no"
                                     v-model="model.ref_no" :disabled="saving"></validated-input>
                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'Tender / Event Title' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Event title"
                                     v-model="model.event_title" :disabled="saving"></validated-input>
                </div>

                <div class="col-md-6 col-12">
                    <validated-vue-select class="c-form-input"
                                          :label="'Event Bank' + (!rules.is_third_party ? '*' : '')"
                                          v-model="model.event_bank"
                                          :options="bankOptions" name="Event bank"
                                          :disabled="disableEventBankField"
                                          :rules="{required: !rules.is_third_party}"></validated-vue-select>

                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'Branch' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Branch"
                                     v-model="model.branch" :disabled="saving"></validated-input>
                </div>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Property Details</h3>
                </div>

                <div class="col-md-6 col-12">
                    <validated-vue-select :label="'Category' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                          class="c-form-input"
                                          :rules="{required: !rules.is_third_party}" :options="propertyOptions"
                                          @input="setSubCategory(model.property_category)" name="Category"
                                          v-model="model.property_category" :disabled="saving"></validated-vue-select>
                </div>

                <div class="col-md-6 col-12">
                    <validated-vue-select :label="'Subcategory' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                          class="c-form-input"
                                          :rules="{required: subPropertyOptions.length > 0 && !rules.is_third_party}"
                                          :options="subPropertyOptions"
                                          v-model="model.property_subcategory" name="Property Subcategory"
                                          :disabled="saving"></validated-vue-select>
                </div>

                <div class="col-12">
                    <validated-input :label="'Borrowers Name' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Borrower name"
                                     v-model="model.borrower_name" :disabled="saving"></validated-input>
                </div>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Auction Details</h3>
                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'Reserved Price' + (!rules.is_third_party ? '*' : '')" name="Reserve price"
                                     placeholder=""
                                     onkeypress="return event.charCode &gt;= 48 &amp;&amp; event.charCode &lt;= 57"
                                     class="c-form-input" :rules="{required: !rules.is_third_party}"
                                     v-model="model.reverse_price" :disabled="saving"></validated-input>
                </div>
                <div class="col-md-6 col-12">
                    <validated-input label="Tender Fee" placeholder=""
                                     class="c-form-input" :rules="{required: false}" name="Tender fee"
                                     onkeypress="return event.charCode &gt;= 48 &amp;&amp; event.charCode &lt;= 57"
                                     v-model="model.tender_fee" :disabled="saving"></validated-input>
                </div>
                <div class="col-md-6 col-12">
                    <validated-input label="Price Bid ( If applicable )" placeholder="" class="c-form-input"
                                     :rules="{required: false}" name="Price bid"
                                     v-model="model.price_bid" :disabled="saving"></validated-input>
                </div>
                <div class="col-md-6 col-12">
                    <validated-input :label="'Increment Value' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Increment value"
                                     onkeypress="return event.charCode &gt;= 48 &amp;&amp; event.charCode &lt;= 57"
                                     v-model="model.increment_value" :disabled="saving"></validated-input>
                </div>
                <div class="col-12">
                    <validated-input :label="'Auto Extension Time' + (!rules.is_third_party ? '*' : '')"
                                     placeholder="Enter how many minutes to extend"
                                     class="c-form-input"
                                     onkeypress="return event.charCode &gt;= 48 &amp;&amp; event.charCode &lt;= 57"
                                     :rules="{required: !rules.is_third_party, min_value:1, max_value:59}"
                                     name="Auto extension time"
                                     v-model="model.auto_extension_time" :disabled="saving"></validated-input>
                </div>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">EMD Details</h3>
                </div>

                <div class="col-md-6 col-12">
                    <validated-input :label="'EMD Amount' + (!rules.is_third_party ? '*' : '')" placeholder=""
                                     class="c-form-input"
                                     :rules="rules.emd_amount" name="Emd amount"
                                     v-model="model.emd_amount" :disabled="saving"></validated-input>
                </div>
                <div class="col-md-6 col-12">
                    <validated-vue-select :label="'EMD Deposit Bank Name' + (!rules.is_third_party ? '*' : '')"
                                          placeholder="" class="c-form-input"
                                          :rules="{required: !rules.is_third_party}" name="Emd bank name"
                                          :options="emdBankOptions"
                                          v-model="model.emd_bank_name" :disabled="saving"></validated-vue-select>
                </div>
                <div class="col-md-6 col-12">
                    <validated-input :label="'EMD Deposit Bank Account Number' + (!rules.is_third_party ? '*' : '')"
                                     placeholder="" class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Emd deposit bank account number"
                                     v-model="model.emd_deposit_bank_account_number"
                                     :disabled="saving"></validated-input>
                </div>
                <div class="col-md-6 col-12">
                    <validated-input :label="'EMD Deposit Bank IFSC Code' + (!rules.is_third_party ? '*' : '')"
                                     placeholder="" class="c-form-input"
                                     :rules="{required: !rules.is_third_party}" name="Emd deposit bank ifsc"
                                     v-model="model.emd_deposit_bank_ifsc" :disabled="saving"></validated-input>
                </div>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Important Dates & Times</h3>
                </div>

                <div class="col-md-3 col-12">
                    <validated-date-picker label="Press Release Date" type="date"
                                           placeholder="" class="c-form-input" @input="clearInspectionDates"
                                           :rules="{required: !rules.is_third_party}" name="Press release date"
                                           v-model="model.press_release_date" :disabled-date="disableFutureDate"
                                           :disabled="saving"></validated-date-picker>
                </div>

                <div class="col-md-3 col-12">
                    <validated-input label="Press Release Time" type="time" placeholder="" @input="clearInspectionDates"
                                     :rules="{required: false}" name="Press Release Time"
                                     class="c-form-input" v-model="model.press_release_time" :disabled="saving"/>
                </div>

                <div class="col-md-3 col-12">
                    <validated-date-picker @input="clearInspectionToDate(true)"
                                           :label="'Date of Inspection of Property (From)' + (!rules.is_third_party ? '*' : '')"
                                           type="date" placeholder=""
                                           :rules="{required: !rules.is_third_party}"
                                           name="Property inspection from date"
                                           class="c-form-input" v-model="model.property_inspection_from_date"
                                           :disabled="saving" :disabled-date="disableInspectionFromDate"/>
                </div>
                <div class="col-md-3 col-12">
                    <validated-input @input="validateFromTime"
                                     :label="'Time of Inspection of Property (From)' + (!rules.is_third_party ? '*' : '')"
                                     type="time" placeholder=""
                                     :rules="{required: !rules.is_third_party}"
                                     name="Time of Inspection of Property (From)"
                                     class="c-form-input" v-model="model.property_inspection_from_time"
                                     :disabled="saving"/>
                    <div class="m-0 p-0 text-danger" v-if="inspectionFromTimeErrorMessage">
                        {{ inspectionFromTimeErrorMessage }}
                    </div>
                </div>
                <div class="col-md-3 col-12">
                    <validated-date-picker @input="clearSubmissionLastDate(true)"
                                           :label="'Date of Inspection of Property (To)' + (!rules.is_third_party ? '*' : '')"
                                           type="date" placeholder="" :rules="{required: !rules.is_third_party}"
                                           name="Property inspection to date" class="c-form-input"
                                           v-model="model.property_inspection_to_date"
                                           :disabled="saving" :disabled-date="disableInspectionToDate"/>
                </div>
                <div class="col-md-3 col-12">
                    <validated-input @input="validateToTime"
                                     :label="'Time of Inspection of Property (To)' + (!rules.is_third_party ? '*' : '')"
                                     type="time" placeholder=""
                                     :rules="{required: !rules.is_third_party}"
                                     name="Time of Inspection of Property (To)"
                                     class="c-form-input" v-model="model.property_inspection_to_time"
                                     :disabled="saving"/>
                    <div class="m-0 p-0 text-danger" v-if="inspectionToTimeErrorMessage">
                        {{ inspectionToTimeErrorMessage }}
                    </div>
                </div>

                <div class="col-md-3 col-12">
                    <validated-date-picker @input="clearOpeningDate(true)"
                                           :label="'Offer (First Round Quote) Submission Last Date' + (!rules.is_third_party ? '*' : '')"
                                           type="date"
                                           placeholder="" :disabled-date="disableSubmissionLastDate"
                                           :rules="{required: !rules.is_third_party}" name="Offer submission last date"
                                           class="c-form-input" v-model="model.offer_submission_last_date"
                                           :disabled="saving"/>
                </div>
                <div class="col-md-3 col-12">
                    <validated-input @input="validateSubmissionLastTime"
                                     :label="'Offer (First Round Quote) Submission Last Time' + (!rules.is_third_party ? '*' : '')"
                                     type="time" placeholder=""
                                     :rules="{required: !rules.is_third_party}"
                                     name="Offer (First Round Quote) Submission Last Time"
                                     class="c-form-input" v-model="model.offer_submission_last_time"
                                     :disabled="saving"/>
                    <div class="m-0 p-0 text-danger" v-if="submissionTimeErrorMessage">
                        {{ submissionTimeErrorMessage }}
                    </div>
                </div>
                <div class="col-md-3 col-12">
                    <validated-date-picker @input="clearAuctionStartDate(true)"
                                           :label="'Offer (First Round Quote) Opening Date' + (!rules.is_third_party ? '*' : '')"
                                           type="date" placeholder=""
                                           :rules="{required: !rules.is_third_party}" name="Offer opening date"
                                           :disabled-date="disableOpeningDate"
                                           class="c-form-input" v-model="model.offer_opening_date" :disabled="saving"/>
                </div>
                <div class="col-md-3 col-12">
                    <validated-input @input="validateOpeningTime"
                                     :label="'Offer (First Round Quote) Opening Time' + (!rules.is_third_party ? '*' : '')"
                                     type="time" placeholder=""
                                     :rules="{required: !rules.is_third_party}"
                                     name="Offer (First Round Quote) Opening Time"
                                     class="c-form-input" v-model="model.offer_opening_time" :disabled="saving"/>
                    <div class="m-0 p-0 text-danger" v-if="openingTimeErrorMessage">
                        {{ openingTimeErrorMessage }}
                    </div>
                </div>
                <div class="col-md-3 col-12">
                    <validated-date-picker @input="clearAuctionEndDate(true)"
                                           :label="'Auction Start Date' + (!rules.is_third_party ? '*' : '')"
                                           type="date" placeholder=""
                                           :rules="{required: !rules.is_third_party}" name="Auction start date"
                                           :disabled-date="disableAuctionStartDate"
                                           class="c-form-input" v-model="model.auction_start_date" :disabled="saving"/>
                </div>
                <div class="col-md-3 col-12">
                    <validated-input :label="'Auction Start Time' + (!rules.is_third_party ? '*' : '')"
                                     type="time" placeholder="" @input="validateAuctionStartTime"
                                     :rules="{required: !rules.is_third_party}" name="Auction start time"
                                     class="c-form-input" v-model="model.auction_start_time" :disabled="saving"/>
                    <div class="m-0 p-0 text-danger" v-if="auctionStartTimeErrorMessage">
                        {{ auctionStartTimeErrorMessage }}
                    </div>
                </div>
                <div class="col-md-3 col-12">
                    <validated-date-picker :label="'Auction End Date' + (!rules.is_third_party ? '*' : '')" type="date"
                                           placeholder="" @input="model.auction_end_time = null"
                                           :rules="{required: !rules.is_third_party}" name="Auction end date"
                                           :disabled-date="disableAuctionEndDate"
                                           class="c-form-input" v-model="model.auction_end_date" :disabled="saving"/>
                </div>
                <div class="col-md-3 col-12">
                    <validated-input :label="'Auction End Time' + (!rules.is_third_party ? '*' : '')" type="time"
                                     placeholder="" @input="validateAuctionEndTime"
                                     :rules="{required: !rules.is_third_party}" name="Auction end time"
                                     class="c-form-input" v-model="model.auction_end_time" :disabled="saving"/>
                    <div class="m-0 p-0 text-danger" v-if="auctionEndTimeErrorMessage">
                        {{ auctionEndTimeErrorMessage }}
                    </div>
                </div>

                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 text-primary font-inter-semi-bold">Documents to be Uploaded</h3>
                </div>

                <div class="col-md-6 col-12">
                    <validated-file-input placeholder="NIT Documents"
                                          :label="'NIT Documents' + (!rules.is_third_party ? '*' : '')"
                                          name="Nit documents"
                                          class="c-file-input mb-1" accept=".zip"
                                          v-model="model.nit_documents" :disabled="saving">
                        <template #append>
                            <div class="form-file-input-tag fl-x-cc text-white px-3">
                                <p class="m-0">Upload</p>
                                <img src="../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                            </div>
                        </template>
                    </validated-file-input>
                    <span class="mt-1 text-info"><sup>*</sup>
                        NIT document should be zip file format.
                    </span>
                </div>
                <div class="col-12">
                    <h3 class="fs-lg-3 py-3 font-inter-semi-bold">Tender Documents</h3>
                </div>

                <div class="col-md-6 col-12">
                    <validated-file-input :label="'Annexure 1' + (!rules.is_third_party ? '*' : '')"
                                          class="c-file-input mb-1"
                                          name="Annexure 1" v-model="model.annexure"
                                          :disabled="saving">
                        <template #append>
                            <div class="form-file-input-tag fl-x-cc text-white px-3">
                                <p class="m-0">Upload</p>
                                <img src="../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                            </div>
                        </template>
                    </validated-file-input>
                    <span
                        class="mt-1 text-info"><sup>*</sup> Please upload files with extensions - <b>jpeg</b>, <b>pdf</b> or <b>docs</b> </span>
                </div>

                <div class="col-md-6 col-12">
                    <validated-file-input :label="'Bid Form' + (!rules.is_third_party ? '*' : '')"
                                          name="Bid Form" class="c-file-input mb-1"
                                          v-model="model.bid_annexure" :disabled="saving">
                        <template #append>
                            <div class="form-file-input-tag fl-x-cc text-white px-3">
                                <p class="m-0">Upload</p>
                                <img src="../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                            </div>
                        </template>
                    </validated-file-input>
                    <span
                        class="mt-1 text-info"><sup>*</sup> Please upload files with extensions - <b>jpeg</b>, <b>pdf</b> or <b>docs</b> </span>
                </div>

                <div class="col-12 pt-lg-4">
                    <validated-file-input :label="'Bidder Declaration Annexure' + (!rules.is_third_party ? '*' : '')"
                                          name="Bidder declaration annexure" class="c-file-input mb-1"
                                          v-model="model.bidder_declaration_annexure" :disabled="saving">
                        <template #append>
                            <div class="form-file-input-tag fl-x-cc text-white px-3">
                                <p class="m-0">Upload</p>
                                <img src="../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                            </div>
                        </template>
                    </validated-file-input>
                    <span
                        class="mt-1 text-info"><sup>*</sup> Please upload files with extensions - <b>jpeg</b>, <b>pdf</b> or <b>docs</b> </span>
                </div>

                                <div class="col-12 mb-3 mt-3">
                    <div class="fl-x fl-j-c py-3 pos-r">
                        <div v-if="auction.auction_items.length === 0" class="pos-a-r-0 mt-2">
                            <btn icon="fa fa-plus" color="secondary" size="sm" text="Add"
                                 @click="$refs.addModal.show()"
                                 v-if="currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_add_auction"/>
                        </div>
                        <div class="btn-group-tight pos-a-r-0 mt-2" v-else>
                            <btn icon="fa fa-pencil" color="secondary"
                                 v-if="currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_edit_auction"
                                 @click="setEdit" class=""/>

                            <btn icon="fa fa-trash" color="danger" @click="setItemDelete"
                                 v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_delete_auction)
                                            && auction.data.status === ''"/>
                        </div>
                        <div class="fl-x fl-a-c mt-5 mb-3">
                            <i class="fa fa-database icon-img mr-2" style="color: blue"></i>
                            <span>Auction Items</span>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <section>
                        <div class="mb-3" v-for="(i, k) in auction.auction_items" v-bind:key="k">
                            <div class="row">

                                <div class="col-6 col-lg-3 ">
                                    <h6 class="font-inter-medium">Item Name </h6>
                                    <p>{{ i.item_name }}</p>
                                </div>

                                <div class="col-6 col-lg-3">
                                    <h6 class="font-inter-medium">Total Items </h6>
                                    <p>{{ i.total_item_numbers }}</p>
                                </div>

                                <div class="col-6 col-lg-3 mt-lg-0 mt-3">
                                    <h6 class="font-inter-medium">State </h6>
                                    <p>{{ i.state }}</p>
                                </div>

                                <div class="col-6 col-lg-3 mt-lg-0 mt-3">
                                    <h6 class="font-inter-medium">District </h6>
                                    <p>{{ i.district }}</p>
                                </div>

                                <div class="col-6 col-lg-3 mt-lg-4 mt-3">
                                    <h6 class="font-inter-medium">Pincode </h6>
                                    <p>{{ i.pincode }}</p>
                                </div>

                                <div class="col-6 col-lg-3 mt-lg-4 mt-3">
                                    <h6 class="font-inter-medium">Item Data Sheet </h6>
                                    <p>
                                        <btn v-if="i.item_data_sheet" @click="setDownload(i.item_data_sheet)"
                                             text="Download" class="btn-xs" icon="fa fa-download"/>

                                        <span v-else>Not Uploaded</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>

                <div v-if="!detailsLoading && auction">
                    <div class="col-12 mt-3">
                        <div class="fl-x fl-j-c fl-a-c py-5 pos-r">
                            <div class="fl-x fl-a-c pos-r">
                                <img class="icon-img mr-2" src="../../assets/web/auction-page-details/image-symbol.png"
                                     alt="">
                                <span>Property Images</span>
                            </div>
                            <btn icon="fa fa-plus" size="sm" color="secondary" text="Add"
                                 v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_add_auction)"
                                 class="ml-3" @click="$refs.addImageModal.show()"></btn>
                        </div>
                    </div>

                    <div class="col-12">
                        <section>

                            <div class="d-grid d-grid-res m-0">
                                <CoolLightBox
                                    :items="propertyImagesList"
                                    :index="index"
                                    @close="index = null">
                                </CoolLightBox>
                                <div class="fl-2 cursor-pointer" v-for="(image, id) in auction.property_images"
                                     v-bind:key="id">
                                    <div class="pos-r w-100 h-100">
                                        <img class="img-fluid w-100 h-100" @click="index = id" :src="image.image"
                                             :alt="'#PropertyImage' + id">

                                        <btn icon="fa fa-trash" @click="setDeleteImage(image)" border-radius="0"
                                             class="pos-a-r-0" v-if="(currentUser.is_superuser || currentUser.is_bank_admin
                                              || currentUser.permission.can_delete_auction)"></btn>

                                    </div>
                                </div>
                            </div>

                            <modal class="c-modal no-close" :no-close-on-backdrop="false" title="" ref="addImageModal"
                                   width="30r"
                                   header-color="">
                                <add-auction-image @success="formSuccess"/>
                            </modal>

                            <delete-modal ref="deleteImageModal" :url="deleteUrl" :params="deletingItem"
                                          @response="deleteComplete">
                                <p>You are about to delete the property image <b
                                    v-html="deletingItem && deletingItem.name"></b>. Are you
                                    sure ?</p>

                                <template #loading>
                                    <loading-animation/>
                                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                                </template>
                            </delete-modal>

                        </section>

                    </div>

                    <div class="col-12 mb-3 mt-3">
                        <div class="fl-x fl-j-c fl-a-c py-5 pos-r">

                            <div class="fl-x fl-a-c">
                                <img class="icon-img mr-2" src="../../assets/web/auction-page-details/video-symbol.png"
                                     alt="">
                                <span>Property Videos</span>
                            </div>
                            <btn icon="fa fa-plus" size="sm" color="secondary" text="Add"
                                 @click="$refs.addVideoModal.show()"
                                 v-if="(currentUser.is_superuser || currentUser.is_bank_admin
                                 || currentUser.permission.can_add_auction)"
                                 class="ml-3"/>
                        </div>
                    </div>

                    <div class="col-12">
                        <section class="mb-5 row">

                            <div class="col-12 col-md-6 col-lg-4 cursor-pointer"
                                 v-for="(video, id) in auction.property_videos"
                                 v-bind:key="id">

                                <div class="px-lg-4 px-2 text-white card-contain bg-cover bg-pos-c bg-4"
                                     :style="{'background-image':'url('+ video.video_thumbnail.image +')'}">

                                    <btn icon="fa fa-trash" border-radius="0" class="pos-a-r-1"
                                         @click="setDeleteVideo(video)"
                                         v-if="(currentUser.is_superuser || currentUser.is_bank_admin
                                         || currentUser.permission.can_delete_auction)"></btn>

                                    <div class="dark-overlay"></div>

                                    <div @click="forceDownload(video.video)"
                                         class="fl-y fl-a-c fl-j-e h-100 card-text video-item" style="cursor: pointer">
                                        <div class="fl-x fl-a-c w-100">
                                            <i class="fa fa-play text-white"></i>
                                            <div>Play</div>
                                        </div>
                                    </div>

                                    <div class="fa-3x icon-center"></div>
                                </div>

                            </div>

                        </section>

                        <modal class="c-modal no-close" :no-close-on-backdrop="false" title="" ref="addVideoModal"
                               width="30r"
                               header-color="">
                            <add-property-video @success="formSuccess"/>
                        </modal>

                        <delete-modal ref="deleteVideoModal" :url="deleteUrl" :params="deletingItem"
                                      @response="deleteComplete">
                            <p>You are about to delete the property video <b
                                v-html="deletingItem && deletingItem.name"></b>. Are you
                                sure ?</p>

                            <template #loading>
                                <loading-animation/>
                                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                            </template>
                        </delete-modal>

                        <modal title="Auction Item Add" ref="addModal" :no-close-on-backdrop="true" width="60r"
                               header-color="primary">
                            <add-auction-item @success="formSuccess"
                                              :is_third_party="auction.auction_details.is_third_party"
                                              @cancelOperation="$refs.addModal.close();"/>
                        </modal>

                        <modal title="Auction Item Update" ref="editModal" :no-close-on-backdrop="true" width="60r"
                               header-color="primary">

                            <edit-auction-item :initial-data="editingItem" @success="formSuccess"
                                               :is_third_party="auction.auction_details.is_third_party"
                                               @cancelOperation="$refs.editModal.close();"/>
                        </modal>

                        <delete-modal ref="deleteItemModal" :url="deleteUrl" :params="deletingItem"
                                      @response="deleteComplete">
                            <p>You are about to delete the Item <b v-html="deletingItem && deletingItem.item_name"></b>.
                                Are you
                                sure ?
                            </p>

                            <template #loading>
                                <loading-animation/>
                                Please wait while we delete <b v-html="deletingItem && deletingItem.item_name"></b>.
                            </template>
                        </delete-modal>
                    </div>
                </div>

                <div class="col-12 mt-3">
                    <div class="fl-x fl-j-c">
                        <div class="btn-group">
                            <btn type="button" text="Cancel" class="px-5" design="basic-b" color="secondary"
                                 @click="setCancel"></btn>

                            <btn text="Update" @click="onSubmit" :loading="saving" loading-text="Updating..."
                                 class="px-5"
                                 design="basic-a" color="secondary"></btn>

                        </div>
                    </div>
                </div>

            </s-form>

        </div>

    </inner-page-card>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import { mapGetters } from 'vuex';
import AddAuctionImage from './add-image/AddAuctionImage';
import AddPropertyVideo from './add-video/AddPropertyVideo';
import propertyVideoImg from '../../assets/web/auction-page-details/video.jpg';
import EditAuctionItem from './auction-items/EditAuctionItem';
import AddAuctionItem from './auction-items/AddAuctionItem';

export default {
    name       : 'EditAuction',
    components : { AddAuctionItem, EditAuctionItem, AddPropertyVideo, AddAuctionImage },
    computed   : {
        ...mapGetters(['currentUser']),
        disableEventBankField () {
            const isAdminOrUser = this.currentUser.is_bank_admin || this.currentUser.is_bank_user;
            const isVenturaOrSuperAdmin = this.currentUser.is_ventura_admin || this.currentUser.is_superuser;
            return isAdminOrUser || (isVenturaOrSuperAdmin && this.auction.data.created_by_id !== this.currentUser.user_id);
        }
    },

    data () {
        return {
            bankOptions           : [],
            emdBankOptions        : [],
            propertyOptions       : [],
            subPropertyOptions    : [],
            allSubPropertyOptions : [],

            rules : {
                is_third_party : false,
                emd_amount     : {
                    required        : true,
                    customValidator : (value) => {
                        return this.CustomAmountValidate(value);
                    }
                }
            },

            model : {
                auction_start_date              : '',
                auction_start_time              : '',
                title                           : '',
                description                     : '',
                event_type                      : '',
                ref_no                          : '',
                event_number                    : '',
                event_title                     : '',
                event_bank                      : '',
                branch                          : '',
                property_category               : '',
                property_subcategory            : '',
                borrower_name                   : '',
                reverse_price                   : '',
                tender_fee                      : '',
                price_bid                       : '',
                increment_value                 : '',
                auto_extension_time             : '',
                emd_bank_name                   : '',
                emd_amount                      : '',
                emd_deposit_bank_account_number : '',
                emd_deposit_bank_ifsc           : '',
                press_release_date              : '',
                property_inspection_from_date   : '',
                property_inspection_to_date     : '',
                offer_submission_last_date      : '',
                offer_opening_date              : '',
                auction_end_date                : '',
                auction_end_time                : '',
                pancard                         : '',
                declaration_form                : '',
                proof_of_address                : '',
                nit_documents                   : '',
                annexure                        : '',
                bid_annexure                    : '',
                bidder_declaration_annexure     : '',
                is_third_party                  : '',
                third_party_name                : '',
                third_party_contact_no          : '',
                third_party_email               : '',
                third_party_link                : ''
            },

            errorMessage                   : '',
            inspectionFromTimeErrorMessage : '',
            inspectionToTimeErrorMessage   : '',
            submissionTimeErrorMessage     : '',
            openingTimeErrorMessage        : '',
            auctionStartTimeErrorMessage   : '',
            auctionEndTimeErrorMessage     : '',
            loading                        : true,
            saving                         : false,
            error                          : false,
            // Auction Video Image Section
            index                          : null,
            propertyVideoImg               : propertyVideoImg,
            detailsLoading                 : false,
            auction                        : null,
            editingItem                    : null,
            deletingItem                   : null,
            deleteUrl                      : null,
            deleteImageUrl                 : urls.auction.auction.auctionImage.delete,
            deleteVideoUrl                 : urls.auction.auction.auctionVideo.delete,
            deleteItemUrl                  : urls.auction.auction.auctionItem.delete,
            propertyImages                 : null,
            propertyImagesList             : [],
            propertyVideosList             : []
            // Auction Video Image Section End
        };
    },

    mounted () {
        this.loadData();
        this.loadAuctionDetails();
    },

    watch : {
        'rules.is_third_party' : {
            handler () {
                this.setAuctionFormErrors();
            }
        }
    },

    methods : {

        setEdit () {
            this.editingItem = { ...this.auction.auction_items[0] };
            this.editingItem.auction = this.editingItem.auction_id;
            this.$refs.editModal.show();
        },

        loadAuctionDetails () {
            const that = this;
            that.loadingDetails = true;
            that.propertyImagesList = [];
            that.error = false;
            const model = {
                auction : that.$route.params.id
            };
            axios.form(urls.auction.auction.info, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.auction = { ...json.data };
                    that.loadingDetails = false;
                    console.log('that.auction : ', that.auction);
                    // set property images list
                    that.auction.property_images.forEach(function (item) {
                        that.propertyImagesList.push(item.image);
                    });
                } else {
                    that.loadingDetails = false;
                    that.error = true;
                }
            }).catch(function (exception) {
                console.log('exception at the incident details loading => ', exception);
                that.loadingDetails = false;
                that.error = true;
            });
        },

        setPropertyImagesList () {
            const that = this;
            this.propertyImages.forEach(function (item) {
                that.propertyImagesList.push(item.image);
            });
        },

        setDeleteImage (imageItem) {
            this.deleteUrl = this.deleteImageUrl;
            this.deletingItem = { ...imageItem };
            this.$refs.deleteImageModal.show();
        },

        setDeleteVideo (videoItem) {
            this.deleteUrl = this.deleteVideoUrl;
            this.deletingItem = { ...videoItem };
            this.$refs.deleteVideoModal.show();
        },

        forceDownload (url) {
            window.open(url);
        },

        formSuccess () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$refs.addImageModal.close();
            this.$refs.addVideoModal.close();
            this.loadAuctionDetails();
        },

        setItemDelete () {
            this.deleteUrl = this.deleteItemUrl;
            this.deletingItem = { ...this.auction.auction_items[0] };
            this.$refs.deleteItemModal.show();
        },

        setDownload (url) {
            window.open(url);
        },

        deleteComplete () {
            this.$refs.deleteImageModal.close();
            this.$refs.deleteVideoModal.close();
            this.$refs.deleteItemModal.close();
            this.$notify('Successfully deleted', 'Success', { type : 'success' });
            this.deletingItem = null;
            this.deleteUrl = null;
            this.loadAuctionDetails();
        },

        changeAuctionType () {
            this.setInitials();
            this.rules.is_third_party = !this.rules.is_third_party;
        },
        setAuctionFormErrors () {
            const formValidator = this.$refs.formValidator;
            if (formValidator) {
                formValidator.reset();
            }
        },

        loadData () {
            const that = this;
            that.loading = true;
            that.error = false;
            const model = {
                auction : that.$route.params.id
            };
            axios.form(urls.auction.auction.editDataFetch + '?type=Text', model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.propertyOptions = [...json.property_type.data];
                    that.bankOptions = [...json.bank.data];
                    that.emdBankOptions = [...json.emd_bank.data];
                    that.subPropertyOptions = [...json.property_sub_category.data];
                    that.allSubPropertyOptions = [...json.property_sub_category.data];
                    that.setDataModel(json.data);
                    that.loading = false;
                } else {
                    that.loading = false;
                    that.error = true;
                }
            }).catch(function (exception) {
                console.log('exception at the incident details loading => ', exception);
                that.loading = false;
                that.error = true;
            });
        },

        setInitials () {
            this.model = {
                auction_start_date              : '',
                auction_start_time              : '',
                title                           : '',
                description                     : '',
                event_type                      : '',
                ref_no                          : '',
                event_number                    : '',
                event_title                     : '',
                event_bank                      : this.currentUser.bank?.id || '',
                branch                          : '',
                property_category               : '',
                property_subcategory            : '',
                borrower_name                   : '',
                reverse_price                   : '',
                tender_fee                      : '',
                price_bid                       : '',
                increment_value                 : '',
                auto_extension_time             : '',
                emd_bank_name                   : '',
                emd_amount                      : '',
                emd_deposit_bank_account_number : '',
                emd_deposit_bank_ifsc           : '',
                press_release_date              : '',
                property_inspection_from_date   : '',
                property_inspection_to_date     : '',
                offer_submission_last_date      : '',
                offer_opening_date              : '',
                press_release_time              : '00:00',
                property_inspection_from_time   : '',
                property_inspection_to_time     : '',
                offer_submission_last_time      : '',
                offer_opening_time              : '',
                auction_end_date                : '',
                auction_end_time                : '',
                pancard                         : '',
                declaration_form                : '',
                proof_of_address                : '',
                nit_documents                   : '',
                annexure                        : '',
                bid_annexure                    : '',
                bidder_declaration_annexure     : '',
                is_third_party                  : '',
                third_party_name                : '',
                third_party_contact_no          : '',
                third_party_email               : '',
                third_party_link                : ''
            };
        },

        setDataModel (model) {
            console.log('auction', this.auction);
            this.auction = { ...this.auction, ...model };
            this.model.id = model.id;
            this.model.auction_start_date = model.auction_start_date;
            this.model.auction_start_time = model.auction_start_time;
            this.model.title = model.title;
            this.model.description = model.description;
            this.model.event_type = model.event_type;
            this.model.ref_no = model.ref_no;
            this.model.event_number = model.event_number;
            this.model.event_title = model.event_title;
            this.model.event_bank = model.event_bank;
            this.model.branch = model.branch;
            this.model.property_category = model.property_category;
            this.model.property_subcategory = model.property_subcategory;
            this.model.borrower_name = model.borrower_name;
            this.model.reverse_price = model.reverse_price;
            this.model.tender_fee = model.tender_fee;
            this.model.price_bid = model.price_bid;
            this.model.increment_value = model.increment_value;
            this.model.auto_extension_time = model.auto_extension_time;
            this.model.emd_bank_name = model.emd_bank_name;
            this.model.emd_amount = model.emd_amount;
            this.model.emd_deposit_bank_account_number = model.emd_deposit_bank_account_number;
            this.model.emd_deposit_bank_ifsc = model.emd_deposit_bank_ifsc;
            this.model.press_release_date = model.press_release_date;
            this.model.property_inspection_from_date = model.property_inspection_from_date;
            this.model.property_inspection_to_date = model.property_inspection_to_date;
            this.model.offer_submission_last_date = model.offer_submission_last_date;
            this.model.offer_opening_date = model.offer_opening_date;
            this.model.press_release_time = model.press_release_time;
            this.model.property_inspection_from_time = model.property_inspection_from_time;
            this.model.property_inspection_to_time = model.property_inspection_to_time;
            this.model.offer_submission_last_time = model.offer_submission_last_time;
            this.model.offer_opening_time = model.offer_opening_time;
            this.model.auction_end_date = model.auction_end_date;
            this.model.auction_end_time = model.auction_end_time;
            this.model.pancard = model.pancard;
            this.model.declaration_form = model.declaration_form;
            this.model.proof_of_address = model.proof_of_address;
            this.model.nit_documents = model.nit_documents;
            this.model.annexure = model.annexure;
            this.model.bid_annexure = model.bid_annexure;
            this.model.bidder_declaration_annexure = model.bidder_declaration_annexure;
            this.model.is_third_party = model.is_third_party;
            this.rules.is_third_party = model.is_third_party;
            if (model.is_third_party) {
                console.log('model.is_third_party', model.is_third_party);
                console.log('model.third_party_name', model.third_party_name);
                this.model.third_party_name = model.third_party_name;
                this.model.third_party_contact_no = model.third_party_contact_no;
                this.model.third_party_email = model.third_party_email;
                this.model.third_party_link = model.third_party_link;
            } else {
                this.model.third_party_name = '';
                this.model.third_party_contact_no = '';
                this.model.third_party_email = '';
                this.model.third_party_link = '';
            }
        },

        CustomAmountValidate (value) {
            const givenAmount = value;
            if (parseInt(givenAmount) > parseInt(this.model.price_bid)) {
                return 'EMD Amount should be less than Price Bid Amount';
            } else {
                return true;
            }
        },

        setCancel () {
            this.$router.push({ path : '/auctions-list/' });
        },

        setSubCategory (item) {
            this.model.property_subcategory = '';
            if (item) {
                const filterValues = this.allSubPropertyOptions.filter(obj => obj.property_type === item);
                this.subPropertyOptions = [...filterValues];
            } else {
                this.subPropertyOptions = [...this.allSubPropertyOptions];
            }
        },

        onSubmit () {
            const conform = confirm('Are You Sure ?');

            if (!conform) {
                return;
            }

            const that = this;
            that.saving = true;
            const formValidator = that.$refs.formValidator;
            that.model.is_third_party = that.rules.is_third_party;

            axios.form(urls.auction.auction.addEdit, that.model).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    that.saving = false;
                    that.$notify('Successfully Updated Auction..!', 'Success',
                        {
                            type : 'success'
                        }
                    );
                    that.$router.go(-1);
                } else {
                    if (json.errors) {
                        that.$notify('Form error found..!', 'Error',
                            {
                                type      : 'warning',
                                duration  : 3000,
                                permanent : false
                            }
                        );
                    } else {
                        that.$notify('Form error found..!', 'Error',
                            {
                                type      : 'warning',
                                duration  : 3000,
                                permanent : false
                            }
                        );
                    }
                    that.saving = false;
                    formValidator.setErrors(json.errors);
                }
            }).catch(function (exception) {
                console.log('exception occurred at the add auction : ', exception);
                that.$notify('Server Error..!', 'Error',
                    {
                        type      : 'warning',
                        duration  : 3000,
                        permanent : false
                    }
                );
                that.saving = false;
            });
        },

        disablePastDate (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },

        disableFutureDate (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            return date > yesterday;
        },

        validateTimeRange (fromDate, fromTime, toDate, toTime, errorKey, errorMessage) {
            this[errorKey] = '';

            if (fromDate && fromTime && toDate && toTime) {
                const formatDateString = (dateString) => {
                    const [day, month, year] = dateString.split('/');
                    return `${year}-${month}-${day}`;
                };

                const fromDateTime = new Date(`${formatDateString(fromDate)} ${fromTime}`);
                const toDateTime = new Date(`${formatDateString(toDate)} ${toTime}`);

                if (toDateTime <= fromDateTime) {
                    this[errorKey] = `${errorMessage}`;
                }
            }
        },

        isDateDisabled (targetDate, currentDate) {
            if (targetDate) {
                const parts = targetDate.split('/');
                const day = parseInt(parts[0], 10);
                const month = parseInt(parts[1], 10) - 1;
                const year = parseInt(parts[2], 10);
                const target = new Date(year, month, day);
                target.setHours(0, 0, 0, 0);

                if (target < new Date()) {
                    return currentDate < new Date();
                } else {
                    return currentDate < target;
                }
            } else {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return currentDate < today;
            }
        },

        disableDatesMainFunction (date, fromDate) {
            const today = new Date();
            const fromDateParts = fromDate.split('/');
            const formattedFromDate = new Date(
                fromDateParts[2], fromDateParts[1] - 1, fromDateParts[0]
            );

            if (formattedFromDate > today) {
                return this.isDateDisabled(fromDate, date);
            } else {
                return this.disablePastDate(date);
            }
        },

        clearDates (dateA, timeA, timeB, errorField, clearAll) {
            this.model[dateA] = null;
            this.model[timeA] = null;
            if (clearAll) {
                this.model[timeB] = null;
            }
            this[errorField] = '';
        },

        clearInspectionDates () {
            this.clearInspectionFromDate();
            this.clearInspectionToDate();
            this.clearSubmissionLastDate();
            this.clearOpeningDate();
            this.clearAuctionStartDate();
            this.clearAuctionEndDate();
        },

        clearInspectionFromDate (clearAll) {
            this.clearInspectionToDate();
            this.clearSubmissionLastDate();
            this.clearOpeningDate();
            this.clearAuctionStartDate();
            this.clearAuctionEndDate();
            this.clearDates(
                'property_inspection_from_date',
                'property_inspection_from_time',
                'press_release_time',
                'inspectionFromTimeErrorMessage',
                clearAll
            );
        },

        clearInspectionToDate (clearAll) {
            this.clearSubmissionLastDate();
            this.clearOpeningDate();
            this.clearAuctionStartDate();
            this.clearAuctionEndDate();
            this.clearDates(
                'property_inspection_to_date',
                'property_inspection_to_time',
                'property_inspection_from_time',
                'inspectionToTimeErrorMessage',
                clearAll
            );
        },

        clearSubmissionLastDate (clearAll) {
            this.clearOpeningDate();
            this.clearAuctionStartDate();
            this.clearAuctionEndDate();
            this.clearDates(
                'offer_submission_last_date',
                'offer_submission_last_time',
                'property_inspection_to_time',
                'submissionTimeErrorMessage',
                clearAll
            );
        },

        clearOpeningDate (clearAll) {
            this.clearAuctionStartDate();
            this.clearAuctionEndDate();
            this.clearDates(
                'offer_opening_date',
                'offer_opening_time',
                'offer_submission_last_time',
                'openingTimeErrorMessage',
                clearAll
            );
        },

        clearAuctionStartDate (clearAll) {
            this.clearAuctionEndDate();
            this.clearDates(
                'auction_start_date',
                'auction_start_time',
                'offer_opening_time',
                'auctionStartTimeErrorMessage',
                clearAll
            );
        },

        clearAuctionEndDate (clearAll) {
            this.clearDates(
                'auction_end_date',
                'auction_end_time',
                'auction_start_time',
                'auctionEndTimeErrorMessage',
                clearAll
            );
        },

        disableInspectionFromDate (date) {
            return this.disableDatesMainFunction(date, this.model.press_release_date);
        },

        disableInspectionToDate (date) {
            return this.disableDatesMainFunction(date, this.model.property_inspection_from_date);
        },

        disableSubmissionLastDate (date) {
            return this.disableDatesMainFunction(date, this.model.property_inspection_to_date);
        },

        disableOpeningDate (date) {
            return this.disableDatesMainFunction(date, this.model.offer_submission_last_date);
        },

        disableAuctionStartDate (date) {
            return this.disableDatesMainFunction(date, this.model.offer_opening_date);
        },

        disableAuctionEndDate (date) {
            return this.disableDatesMainFunction(date, this.model.auction_start_date);
        },

        validateFromTime () {
            this.clearInspectionToDate(false);
            this.validateTimeRange(
                this.model.press_release_date,
                this.model.press_release_time,
                this.model.property_inspection_from_date,
                this.model.property_inspection_from_time,
                'inspectionFromTimeErrorMessage',
                'Inspection from time should be greater than press release time'
            );
        },

        validateToTime () {
            this.clearSubmissionLastDate(false);
            this.validateTimeRange(
                this.model.property_inspection_from_date,
                this.model.property_inspection_from_time,
                this.model.property_inspection_to_date,
                this.model.property_inspection_to_time,
                'inspectionToTimeErrorMessage',
                'Inspection to time should be greater than Inspection from time'
            );
        },

        validateSubmissionLastTime () {
            this.clearOpeningDate(false);
            this.validateTimeRange(
                this.model.property_inspection_to_date,
                this.model.property_inspection_to_time,
                this.model.offer_submission_last_date,
                this.model.offer_submission_last_time,
                'submissionTimeErrorMessage',
                'Submission last time should be greater than Inspection to time'
            );
        },

        validateOpeningTime () {
            this.clearAuctionStartDate(false);
            this.validateTimeRange(
                this.model.offer_submission_last_date,
                this.model.offer_submission_last_time,
                this.model.offer_opening_date,
                this.model.offer_opening_time,
                'openingTimeErrorMessage',
                'Opening time should be greater than Submission last time'
            );
        },

        validateAuctionStartTime () {
            this.clearAuctionEndDate(false);
            this.validateTimeRange(
                this.model.offer_opening_date,
                this.model.offer_opening_time,
                this.model.auction_start_date,
                this.model.auction_start_time,
                'auctionStartTimeErrorMessage',
                'Auction start time should be greater than Opening time'
            );
        },

        validateAuctionEndTime () {
            this.validateTimeRange(
                this.model.auction_start_date,
                this.model.auction_start_time,
                this.model.auction_end_date,
                this.model.auction_end_time,
                'auctionEndTimeErrorMessage',
                'Auction end time should be greater than Auction start time'
            );
        }

    }
};
</script>

<style scoped>

    .bullet {
        width: 0.6rem;
        height: 0.6rem;
        background-color: var(--bg-10) !important;
        border: 1px solid gray;
        border-radius: 50%;
    }

    .vertical-line {
        height: 1.2rem;
        width: 2px;
        background-color: rgba(128, 128, 128, 1);
    }

    .line-bottom-1 {
        height: 3px;
        width: 100%;
        /*background-color: rgba(255);*/
    }

    .bg-blue-layer {
        background: url("../../assets/web/bg/blue-strip-alternate.jpg");
    }

    .c-margin-x {
        margin: -15px -15px 0 -15px;
    }

    .icon-md {
        height: 2.5rem !important;
    }

    .bullet-2 {
        position: absolute;
        left: -1rem;
        top: 50%;
        transform: translateY(-50%);
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        background-color: var(--color-secondary);
    }

    .video-item {
        min-height: 11rem !important;
    }

    .pos-a-r-1 {
        position: absolute;
        top: 0;
        right: 0.934rem;
    }

    .bg-contain {
        background-size: contain;
    }

    .clearfix {
        overflow: auto;
        float: right;
        display: inline-block;
        width: 50%;
    }
</style>
